<template>
  <div v-loading="loading">
    <el-row class="statistics_top">
      <el-card class="box-card" style="width: 49%">
        <div slot="header" class="clearfix">
          <span class="title_fw">商务类型</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'BusinessType'"
              :introductionType="'staffId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.businessTypePrincipal"
              v-model="sectionInfo.businessTypeYear"
              @change="onBusinessTypeChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'BusinessType'"
              :introductionType="'staffId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.businessTypeYear"
              v-model="sectionInfo.businessTypePrincipal"
              @change="onBusinessTypeChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onBusinessTypeImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <BusinessType :businessTypeList="businessTypeList" /> -->
      </el-card>
      <el-card class="box-card" style="width: 49%">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目来源</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'ProjectSource'"
              :introductionType="'staffId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.projectSourcePrincipal"
              v-model="sectionInfo.projectSourceYear"
              @change="onProjectSourceChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'ProjectSource'"
              :introductionType="'staffId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.projectSourceYear"
              v-model="sectionInfo.projectSourcePrincipal"
              @change="onProjectSourceChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onProjectSourceImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <ProjectSource :projectSourceList="projectSourceList" /> -->
      </el-card>
    </el-row>
    <el-row class="statistics_top" style="margin-top: 8px">
      <el-card class="box-card" style="width: 49%">
        <div slot="header" class="clearfix">
          <span class="title_fw">业务种类</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'BusinessLines'"
              :introductionType="'staffId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.businessLinesPrincipal"
              v-model="sectionInfo.businessLinesYear"
              @change="onBusinessLinesChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'BusinessLines'"
              :introductionType="'staffId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.businessLinesYear"
              v-model="sectionInfo.businessLinesPrincipal"
              @change="onBusinessLinesChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onBusinessLinesImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <BusinessLines :businessLinesList="businessLinesList" /> -->
      </el-card>
      <el-card class="box-card" style="width: 49%">
        <div slot="header" class="clearfix">
          <span class="title_fw">商务漏斗分析</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :type="'BusinessFunnel'"
              :introductionType="'staffId'"
              :yearIsShow="true"
              :list="dictData.yearList"
              :twoValue="sectionInfo.funnelPrincipal"
              v-model="sectionInfo.funnelYear"
              @change="onBusinessFunnelChange"
          /></span>
          <span class="title_right" v-if="isBoss"
            ><SearchUserDate
              :clearable="true"
              :filterable="true"
              :type="'BusinessFunnel'"
              :introductionType="'staffId'"
              :title="titlePrincipal"
              :list="userList"
              :yearValue="sectionInfo.funnelYear"
              v-model="sectionInfo.funnelPrincipal"
              @change="onBusinessFunnelChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onBusinessFunnelImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <BusinessFunnel :funnelList="funnelList" /> -->
      </el-card>
    </el-row>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          :src="`https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/${imgUrl}.png?${new Date().getTime()}`"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    // BusinessLines: () => import('../businessLines.vue'),
    // BusinessType: () => import('../businessType.vue'),
    // ProjectSource: () => import('../projectSource.vue'),
    // BusinessFunnel: () => import('../businessFunnel.vue'),
    // SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      userList: [], // 用户数组
      dictData: {
        businessLines: [],
        yearList: [],
        projectSource: [],
        businessType: [],
      },
      businessLinesList: [], // 业务种类
      businessTypeList: [], // 商务类型
      projectSourceList: [], // 项目来源
      funnelList: [], // 商务漏斗
      sectionInfo: {
        // 条件筛选 // 年份
        businessLinesYear: null,
        businessTypeYear: null,
        projectSourceYear: null,
        funnelYear: null, // 负责人
        businessLinesPrincipal: '',
        businessTypePrincipal: '',
        projectSourcePrincipal: '',
        funnelPrincipal: '',
      },
      isBoss: false, // 是否BOSS
      titleYear: '统计时间',
      titlePrincipal: '商务负责人',
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {},
  watch: {},
  mounted() {},
  methods: {
    onBusinessFunnelImg() {
      this.imgUrl = '商务漏斗分析'
      this.isDialog = true
    },
    onProjectSourceImg() {
      this.imgUrl = '商务类型分析'
      this.isDialog = true
    },
    onBusinessLinesImg() {
      this.imgUrl = '商务统计业务种类'
      this.isDialog = true
    },
    onBusinessTypeImg() {
      this.imgUrl = '商务类型分析'
      this.isDialog = true
    },
    onBusinessLinesChange(arr) {
      this.businessLinesList = arr
    },
    onBusinessTypeChange(arr) {
      this.businessTypeList = arr
    },
    onProjectSourceChange(arr) {
      this.projectSourceList = arr
    },
    onBusinessFunnelChange(arr) {
      this.funnelList = arr
    },
    async getType() {
      const businessLines = await this.$api.dict.listSysDictData('BUSINESS_LINES', true)
      const businessType = await this.$api.dict.listSysDictData('BUSINESS_TYPE', true)
      const projectSource = await this.$api.dict.listSysDictData('PROJECT_SOURCE', true)
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.businessLinesYear = dictVal
          this.sectionInfo.businessTypeYear = dictVal
          this.sectionInfo.projectSourceYear = dictVal
          this.sectionInfo.funnelYear = dictVal

          const queryBusinessTypeSummary = await this.$api.statistics.queryBusinessTypeSummary(
            `date=${dictVal}`
          )
          this.isBoss = queryBusinessTypeSummary.data.isBoss
          this.businessTypeList =
            queryBusinessTypeSummary.data?.businessAndProjectResponse?.businessTypeResponse.map(
              item => {
                const businessTypeItem = businessType.data.find(
                  v => v.dictVal === item.businessTypeName
                )
                return {
                  value: item.businessTypeAmount,
                  businessPercentage: Number((item.businessPercentage * 100).toFixed(2)),
                  businessTypeCount: item.businessTypeCount,
                  name: businessTypeItem ? businessTypeItem.dictName : item.businessTypeName, // 如果找不到则使用原名称
                }
              }
            )

          this.projectSourceList =
            queryBusinessTypeSummary.data?.businessAndProjectResponse?.projectSourceResponse.map(
              item => {
                const projectSourceItem = projectSource.data.find(
                  v => v.dictVal === item.projectSourceName
                )
                return {
                  value: item.projectSourceAmount,
                  projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
                  projectSourceCount: item.projectSourceCount,
                  name: projectSourceItem ? projectSourceItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
                }
              }
            )

          const queryBusinessCategorySummary =
            await this.$api.statistics.queryBusinessCategorySummary(`date=${dictVal}`)
          this.businessLinesList =
            queryBusinessCategorySummary.data.businessCategoryResponseList.map(item => {
              const businessLinesItem = businessLines.data.find(
                v => v.dictVal === item.categoryName
              )
              return {
                value: item.categoryAmount,
                percentage: Number((item.percentage * 100).toFixed(2)),
                categoryCount: item.categoryCount,
                name: businessLinesItem ? businessLinesItem.dictName : item.categoryName, // 如果找不到则使用原名称
              }
            })

          const queryFunnelStatisticsSummary =
            await this.$api.statistics.queryFunnelStatisticsSummary(`date=${dictVal}`)
          const { funnelStatisticsResponse = {} } = queryFunnelStatisticsSummary.data

          const nameMapping = {
            newBusinessCount: { name: '新增商务数', value: 952358 },
            projectCount: { name: '立项数', value: 394841 },
            contractSigningCount: { name: '合同签约数', value: 63497 },
          }

          this.funnelList = Object.keys(funnelStatisticsResponse)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: nameMapping[key].name, // 获取名称
              value: nameMapping[key].value, // 获取对应值
              values: funnelStatisticsResponse[key], // 原始数据
            }))

          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
      // 获取商务负责人
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.userList = res?.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.userList.unshift({
            id: `${Math.random()}`,
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>
